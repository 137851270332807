import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import { Auditor, SubjectAutocomplete } from '@models';

export function useColumns(): TableColumn<Auditor>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Auditor>();

  const { licenceStates } = useContext(StaticDataContext);
  const useLicenceStates = () => useStaticListSource(licenceStates);

  return [
    {
      datakey: 'subject.fullName',
      filterkey: 'subject.id',
      sortkey: 'subject.fullName',
      name: 'Meno a priezvisko',
      width: 250,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useNaturalPersons,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'slovakLicence.number',
      name: 'Číslo licencie',
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: FilterTextNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'slovakCertification.number',
      name: 'Číslo certifikátu',
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: FilterTextNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterGroup: 2,
      filterable: true,
    },
    /*
    TODO: Odkomentovat ked bude BE ready
    aby sa zmeny prejavili aj v pripade ze uzivatel ma ulozene zmene poradie stlpcov
    je potrebne tiez zvysit verziu evidencie:
    subor src\modules\auditor\auditors.tsx => version zvysit o 1
    {
      datakey: 'certificates',
      name: 'Zahraničné certifikáty',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterGroup: 2,
      filterable: true,
    },
    */
    {
      datakey: 'businessAddress.label',
      name: 'Miesto podnikania',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'slovakLicence.state',
      sortkey: 'slovakLicence.state.name',
      filterkey: 'slovakLicence.state.id',
      name: 'Stav licencie',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useLicenceStates),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useLicenceStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'subject.address.label',
      name: 'Adresa',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
