import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { AuditCompany } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const INSURANCE_SOURCE = 'INSURANCE_SOURCE';
export const STAKEHOLDER_SOURCE = 'STAKEHOLDER_SOURCE';
export const DISCIPLINARY_RECORD_SOURCE = 'DISCIPLINARY_RECORD_SOURCE';
export const EMPLOYMENT_SOURCE = 'EMPLOYMENT_SOURCE';
export const EMPLOYMENT_INVITATION_SOURCE = 'EMPLOYMENT_INVITATION_SOURCE';

function useDisciplinaryRecordSource(): HookedSource<AuditCompany> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [DISCIPLINARY_RECORD_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_INSURANCE_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.AUDITING_ENTITY}/${id}/disciplinary/list`
        );
        source.setParams({
          size: -1,
          filters: [],
        });
      },
    },
  };
}

function useInsuranceSource(): HookedSource<AuditCompany> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [INSURANCE_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_INSURANCE_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.INSURANCE}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'insuredSubjects.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useStakeholderSource(): HookedSource<AuditCompany> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [STAKEHOLDER_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_STAKEHOLDER_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/stakeholders/list`
        );
      },
    },
  };
}

function useEmploymentSource(): HookedSource<AuditCompany> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [EMPLOYMENT_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_EMPLOYMENT_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.AUDIT_COMPANY}/${id}/employments/list`);
      },
    },
  };
}

function useEmploymentInvitationSource(): HookedSource<AuditCompany> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [EMPLOYMENT_INVITATION_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_EMPLOYMENT_INVITATION_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.AUDIT_COMPANY}/${id}/invitations/list`);
      },
    },
  };
}

export function useAuditCompanyHookedSources(): HookedSource<AuditCompany> {
  const insurances = useInsuranceSource();
  const stakeholders = useStakeholderSource();
  const employments = useEmploymentSource();
  const employmentInvitations = useEmploymentInvitationSource();
  const disciplinaryRecords = useDisciplinaryRecordSource();

  return {
    ...insurances,
    ...stakeholders,
    ...employments,
    ...employmentInvitations,
    ...disciplinaryRecords,
  };
}
