import React, { useContext } from 'react';
import {
  FormAutocomplete,
  FormPanel,
  FormTextArea,
  UserContext,
} from '@eas/common-web';
import { useSubjects } from '@modules/subject/subject-api';
import { EmploymentInvitationField } from '@composite/employment-invitations/employment-invitation-field';
import { EmploymentField } from '@composite/employments/employment-field';
import { StakeholderField } from '@composite/stakeholders/stakeholder-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { SubjectAutocomplete } from '@models';
import { Permission } from '@enums';

export function MemberFields() {
  const subjects = useSubjects();
  const { hasPermission } = useContext(UserContext);

  return (
    <>
      {hasPermission(Permission.BO_STAKEHOLDER_READ) && (
        <StakeholderField
          label="Zainteresované strany"
          display="IN_AUDIT_COMPANY"
          disabled={!hasPermission(Permission.BO_STAKEHOLDER_MANAGE)}
        />
      )}
      {hasPermission(Permission.BO_EMPLOYMENT_READ) && (
        <EmploymentField
          display="IN_AUDIT_COMPANY"
          disabled={!hasPermission(Permission.BO_EMPLOYMENT_MANAGE)}
        />
      )}
      {hasPermission(Permission.BO_EMPLOYMENT_INVITATION_READ) && (
        <EmploymentInvitationField
          disabled={!hasPermission(Permission.BO_EMPLOYMENT_INVITATION_MANAGE)}
        />
      )}
      <FormPanel label="Vlastníci a akcionári spoločnosti">
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="stakeholders"
          multiple
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
        />
      </FormPanel>
      <FormPanel label="Členstvo audítorskej spoločnosti v sieti a zoznam všetkých subjektov začlenených do siete a pridružených spoločností audítorských spoločností a ich sídlo alebo odkaz na miesto, kde sú tieto informácie prístupné verejnosti">
        <FormTextArea name="membership" labelOptions={{ hide: true }} />
      </FormPanel>
    </>
  );
}
