import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useExpireDialog } from './dialog-actions/expire-hook';
import { useRenewDialog } from './dialog-actions/renew-hook';
import { useRevokeDialog } from './dialog-actions/revoke-hook';
import { useSuspendDialog } from './dialog-actions/suspend-hook';

export function ActionBar() {
  const { showSuspendBtn, ...suspendProps } = useSuspendDialog();
  const { showRevokeBtn, ...revokeProps } = useRevokeDialog();
  const { showRenewBtn, ...renewProps } = useRenewDialog();
  const { showExpireBtn, ...expireProps } = useExpireDialog();

  return (
    <>
      {showRevokeBtn && (
        <DetailActionButton
          promptKey="REVOKE"
          buttonLabel="Odobrať"
          dialogText="Naozaj chcete odobrať licenciu?"
          dialogTitle="Odobratie"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...revokeProps}
        />
      )}
      {showSuspendBtn && (
        <DetailActionButton
          promptKey="SUSPEND"
          buttonLabel="Pozastaviť"
          dialogText="Naozaj chcete pozastaviť licenciu?"
          dialogTitle="Pozastavenie"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...suspendProps}
        />
      )}
      {showExpireBtn && (
        <DetailActionButton
          promptKey="EXPIRE"
          buttonLabel="Zaniknúť"
          dialogText="Naozaj chcete zmeniť stav licencie na zaniknutú?"
          dialogTitle="Zánik licencie"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...expireProps}
        />
      )}
      {showRenewBtn && (
        <DetailActionButton
          promptKey="RENEW"
          buttonLabel="Obnoviť"
          dialogText="Naozaj chcete obnoviť licenciu?"
          dialogTitle="Obnovenie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...renewProps}
        />
      )}
    </>
  );
}
