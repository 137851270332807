import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { EMPLOYMENT_SOURCE } from '@modules/audit-company/audit-company-hooked-sources';
import { RemoteTableToolbar } from '@components/toolbar/remote-table-toolbar';
import { EvidenceApiUrl } from '@enums';
import {
  addEmploymentApiCall,
  deleteEmploymentApiCall,
  editEmploymentApiCall,
} from './employment-api';
import { useColumns } from './employment-columns';
import { EmploymentDialog } from './employment-dialog';
import { useValidationSchema } from './employment-schema';

export function EmploymentField({
  disabled = false,
  display,
}: {
  disabled?: boolean;
  display: 'IN_AUDIT_COMPANY' | 'IN_AUDITOR' | 'IN_SUBJECT';
}) {
  const detailCtx = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[EMPLOYMENT_SOURCE].source;

  const validationSchema = useValidationSchema();
  const columns = useColumns(display);

  const Toolbar = (props: TableFieldToolbarProps) => (
    <RemoteTableToolbar
      {...props}
      source={source}
      dialogTitle="Zamestnanie"
      validationSchema={validationSchema}
      Dialog={EmploymentDialog}
      addButtonProps={{
        promptKey: 'ADD_EMPLOYMENT',
        apiCall: addEmploymentApiCall,
      }}
      editButtonProps={{
        promptKey: 'EDIT_EMPLOYMENT',
        apiCall: editEmploymentApiCall,
      }}
      deleteButtonProps={{
        promptKey: 'DELETE_EMPLOYMENT',
        apiCall: deleteEmploymentApiCall,
      }}
      url={`${EvidenceApiUrl.AUDIT_COMPANY}/${detailCtx?.source?.data?.id}/employments`}
    />
  );

  return (
    <FormPanel label="Zamestnania" expandable={true} defaultExpanded={true}>
      <RemoteTableField
        source={source}
        columns={columns}
        showDetailBtnCond={stubFalse}
        ToolbarComponent={Toolbar}
        showToolbar={!disabled}
        disabled={disabled}
      />
    </FormPanel>
  );
}
