import { differenceBy } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormTextArea,
  useFormSelector,
} from '@eas/common-web';
import { useEmploymentPositions } from '@modules/dict-employment-position/dict-employment-position-api';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  DictEmploymentPosition,
  DictEmploymentPositionAutocomplete,
  Employment,
  SubjectAutocomplete,
} from '@models';

export function EmploymentDialog() {
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const subjects = useNaturalPersons();
  const positionSource = useEmploymentPositions();

  const { positions } = useFormSelector((data: Employment) => ({
    positions: data.positions ?? [],
  }));

  return (
    <>
      <FormAutocomplete<SubjectAutocomplete>
        source={subjects}
        label="Zamestnanec"
        name="subject"
        labelMapper={autocompleteLabelMapper}
        required
        disabled={mode !== DetailMode.NEW}
      />
      <FormAutocomplete<DictEmploymentPositionAutocomplete>
        source={positionSource}
        label="Popis pozície"
        name="positions"
        labelMapper={autocompleteLabelMapper}
        multiple
        multiline
        required
        notifyChange={(value) => {
          const newPositions = differenceBy(
            (value as DictEmploymentPosition[]) ?? [],
            positions,
            'id'
          );

          if (
            newPositions?.some(
              (p) => (p as DictEmploymentPosition)?.flagsEnabled
            )
          ) {
            setFieldValue('employeeManagement', true);
            setFieldValue('insuranceManagement', true);
            setFieldValue('auditReporting', true);
          }
        }}
      />
      <FormCheckbox label="Spravovanie audítorov" name="employeeManagement" />
      <FormCheckbox label="Správa poistenií" name="insuranceManagement" />
      <FormCheckbox
        label="Zápis auditov a ich hlásenie na UDVA"
        name="auditReporting"
      />
      <FormTextArea label="Poznámka" name="note" />
    </>
  );
}
