import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import {
  AuditingEntityAutocomplete,
  DictCountryAutocomplete,
  Licence,
} from '@models';

export function useColumns(): TableColumn<Licence>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Licence>();

  const { licenceStates, licenceTypes } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(licenceStates);
  const useLicenceTypes = () => useStaticListSource(licenceTypes);

  return [
    {
      datakey: 'number',
      name: 'Číslo licencie',
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: FilterTextNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'issuedDate',
      name: 'Dátum vydania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Licencovaný subjekt',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'origin.country',
      sortkey: 'origin.country.name',
      filterkey: 'origin.country.id',
      name: 'Krajina pôvodu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictCountryAutocomplete>(
          useCountries,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'origin.institution',
      name: 'Vydávajúci orgán',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'type',
      name: 'Typ licencie',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortkey: 'type.name',
      filterkey: 'type.id',

      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useLicenceTypes),
      valueMapper: TableCells.useSelectCellFactory(useLicenceTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
